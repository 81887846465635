import { thisYear } from '../helpers/types';

export const nhsChecksum = number =>
  `${number} is not a valid NHS, CHI or H&C number`;

export const isRequired = fieldName => `${fieldName} is required`;

export const isNumeric = fieldName => `${fieldName} must be numeric`;

export const isPositive = fieldName => `${fieldName} must be zero or greater`;

export const isWholeNumber = fieldName => `${fieldName} must be a whole number`;

export const isEmail = email => {
  return () => `${email} is not a valid email`;
};

export const mustMatch = otherFieldName => {
  return fieldName => `${fieldName} must match ${otherFieldName}`;
};

export const minLength = length => {
  return fieldName => `${fieldName} must be at least ${length} characters`;
};

export const isUKDateOrYear = fieldName =>
  `${fieldName} is not a valid date of the form dd/mm/yyyy or yyyy`;

export const isUKDate = fieldName =>
  `${fieldName} is not a valid date of the form dd/mm/yyyy`;

export const isUKYearInRange = fieldName =>
  `${fieldName} year must be between 1800 and ${thisYear() + 1}`;

export const isUKMobileNumber = phone => {
  return () => `${phone} is not a valid UK mobile number`;
};

export const isBetween = (start, end) => {
  return fieldName =>
    `${fieldName} must be a number between ${start} and ${end}`;
};

export const unsafeText = fieldName =>
  `${fieldName} contains invalid characters`;

export const isOnlyDateChars = fieldName =>
  `${fieldName} must contain only numeric and forward slash / characters`;

export const isDiagnosisDateInTheFuture = fieldName =>
  `${fieldName} puts the diagnosis in the future`;

export const isDiagnosisAfterDeath = fieldName =>
  `${fieldName} puts the diagnosis after the entered date of death`;

export const isDateOfDeathBeforeBirth = fieldName =>
  `${fieldName} is before the entered date of birth`;
